import React, { useState, useEffect, useRef } from "react";
import { FaBars } from "react-icons/fa6";
import Dropdown from "./DropDownMenu";
import AnimatedButton from "./AnimatedButton";
import { connectToMetaMask, disconnectFromMetaMask } from "./Web3Provider";

export default function Header() {
  const [isScrolled, setIsScrolled] = useState(false);
  const [walletAddress, setWalletAddress] = useState(null);
  const [metaMaskError, setMetaMaskError] = useState(null);
  const [profilePicture, setProfilePicture] = useState(null);
  const [showLogout, setShowLogout] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      setIsScrolled(scrollTop > 0);
    };

    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowLogout(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleConnectWallet = async () => {
    try {
      const { address, error, profilePicture } = await connectToMetaMask();
      if (error) {
        setMetaMaskError(error);
      } else {
        setWalletAddress(address);
        setProfilePicture(profilePicture);
        setMetaMaskError(null);
      }
    } catch (error) {
      console.error("Failed to connect to MetaMask:", error);
      setMetaMaskError("Failed to connect to MetaMask");
    }
  };

  const handleDisconnectWallet = async () => {
    try {
      await disconnectFromMetaMask();
      setWalletAddress(null);
      setProfilePicture(null);
      setShowLogout(false);
    } catch (error) {
      console.error("Failed to disconnect from MetaMask:", error);
    }
  };

  const toggleLogout = () => {
    setShowLogout(!showLogout);
  };

  return (
    <div className={`frame container ${isScrolled ? "scrolled" : ""}`}>
      <div className="frame__title">
        <a href="https://memotics.io/">
          <img src="images/logo.webp" alt="Logo" className="frame__title-logo" />
        </a>
      </div>

      {walletAddress ? (
        <div className="wallet-profile" ref={dropdownRef}>
          <img
            src={profilePicture || "default-profile.png"}
            alt="Profile"
            className="profile-picture"
            onClick={toggleLogout}
          />
          <div className={`logout-dropdown ${showLogout ? 'show' : ''}`}>
            <button onClick={handleDisconnectWallet} className="logout-button">
              Log out
            </button>
          </div>
        </div>
      ) : (
        <button onClick={handleConnectWallet} className="connect-wallet-button">
          Connect Wallet
        </button>
      )}

      {metaMaskError && <p className="meta-mask-error">{metaMaskError}</p>}

      <a className="topnav">
        <AnimatedButton />
        <Dropdown />
      </a>
    </div>
  );
}