import { ethers } from 'ethers';

// Function to connect to MetaMask
export const connectToMetaMask = async () => {
  console.log("Checking for MetaMask...");
  
  if (typeof window.ethereum !== 'undefined') {
    console.log("MetaMask detected");

    try {
      // Request account access if needed
      await window.ethereum.request({ method: 'eth_requestAccounts' });

      // Create a provider
      const provider = new ethers.BrowserProvider(window.ethereum);

      // Get the signer
      const signer = await provider.getSigner();

      // Get the user's address
      const address = await signer.getAddress();

      console.log("Connected address:", address);

      // Get the profile picture
      const profilePicture = await getMetaMaskProfilePicture(provider, address);

      return { provider, signer, address, profilePicture };
    } catch (error) {
      console.error('User denied account access', error);
      throw new Error('User denied account access');
    }
  } else {
    console.error('MetaMask is not installed');
    return { error: 'MetaMask is not installed' };
  }
};

// Function to get MetaMask profile picture
const getMetaMaskProfilePicture = async (provider, address) => {
  try {
    // Try to get ENS name for the address
    const ensName = await provider.lookupAddress(address);
    
    if (ensName) {
      // If ENS name exists, try to get the avatar
      const resolver = await provider.getResolver(ensName);
      if (resolver) {
        const avatar = await resolver.getAvatar();
        if (avatar) {
          return avatar.url;
        }
      }
    }
  } catch (error) {
    console.error("Error fetching ENS avatar:", error);
  }
  
  // Fallback to generating an avatar based on the address
  return `https://api.dicebear.com/6.x/identicon/svg?seed=${address}`;
};

// Function to disconnect from MetaMask
export const disconnectFromMetaMask = async () => {
  // Note: MetaMask doesn't provide a way to programmatically disconnect
  // This function just clears the local Web3 instance
  console.log("Disconnecting from MetaMask");
  // You might want to clear any stored state or connection information here
};